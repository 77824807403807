<template>
  <div class="cont-box">
    <el-table :data="datas" style="width: 100%;" max-height="580" border>
      <el-table-column fixed prop="employeeName" label="姓名"></el-table-column>
      <el-table-column prop="center" label="中心"></el-table-column>
      <el-table-column prop="department" label="部门"></el-table-column>
      <el-table-column prop="job" label="岗位" ></el-table-column>
      <el-table-column prop="itemRank" label="职能"></el-table-column>
      <el-table-column prop="idCard" label="身份证号"></el-table-column>
      <el-table-column prop="phone" label="个人联系方式"></el-table-column>
      <el-table-column prop="birthday" label="出生日期"></el-table-column>
      <el-table-column prop="enterDate" label="入司时间" width="120"></el-table-column>
    </el-table>
  </div>
</template>

<script>
  export default {
    name: 'RosterConfirm',
    props: ['datas'],
    data() {
      return {
      }
    }
  }
</script>

<style scoped>
</style>
